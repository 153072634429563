import React, { useEffect, useState } from "react";
import axios from "axios";
import { Stack, Box } from "@mui/material";
import DashboardButton from "./DashboardButton";

const DashboardButtons = () => {
  const [outstandingCheckCount, setOutstandingChecksCount] = useState<number | undefined>();
  const [policiesNotIssuedCount, setPoliciesNotIssuedCount] = useState<number | undefined>();
  const [escrowOverdueOrdersCount, setEscrowOverdueOrdersCount] = useState<number | undefined>();
  const [nonZeroLedgerBalances, setNonZeroLedgerBalances] = useState<number | undefined>();

  const getData = async (url: string, setter: React.Dispatch<React.SetStateAction<number>>) => {
    const { data } = await axios.get(url);
    setter(data.count);
  };

  useEffect(() => {
    const urls = [
      { url: "/api/Dashboard/GetUnclearedChecksCount", setter: setOutstandingChecksCount },
      { url: "/api/Dashboard/GetPoliciesNotIssued", setter: setPoliciesNotIssuedCount },
      { url: "/api/Dashboard/GetEscrowOverdueOrders", setter: setEscrowOverdueOrdersCount },
      { url: "/api/Dashboard/GetNonZeroLedgerBalance", setter: setNonZeroLedgerBalances }
    ];
    urls.forEach((u) => getData(u.url, u.setter));
  }, []);

  return (
    <Box display="flex" justifyContent="center">
      <Stack direction="row" spacing={2} marginBottom={2}>
        <DashboardButton
          text={"Outstanding Checks"}
          count={outstandingCheckCount}
          color="info"
          href="https://titleq.mcres.com/checks"
        />
        <DashboardButton
          text={"Policies Not Issued"}
          count={policiesNotIssuedCount}
          color="success"
          href="https://titleq.mcres.com/policies-not-issued"
        />
        <DashboardButton
          text={"Escrow Overdue"}
          count={escrowOverdueOrdersCount}
          color="primary"
          href="https://titleq.mcres.com/escrow-ledgers"
        />
        <DashboardButton
          text={"Non Zero Ledger Balances"}
          count={nonZeroLedgerBalances}
          color="secondary"
          href="https://titleq.mcres.com/non-zero-ledger-balances"
        />
      </Stack>
    </Box>
  );
};

export default DashboardButtons;
